<template>
  <b-container class="col">
    <div class="row justify-content-center align-items-center">
      <img :src="require('@/assets/images/logo/delivery.png')" class="w-50" />
    </div>
    <div class="d-flex row">
      <p class="h1 w-100 font-medium-3 text-center">Coming Soon!</p>
    </div>
  </b-container>
</template>

<script>
import { BCard, BCardText, BContainer, BLink, BCol, BRow } from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BContainer,
    BLink,
    BRow,
  },
};
</script>

<style scoped></style>
